
.agree{
    text-align:left;
    margin-top:20px;
  }
  .agree-title{
    font-size: 16px;
    color: #000;
    font-weight: bold;
  }
  .agree-content{
    font-size: 16px;
    color: #666666;
    padding-bottom: 10px;
  }
  .agree-link {
    float: right;
    margin: 10px 0px 0px 0px;
    cursor: pointer;
  }
  .agree .ant-form-item-explain {
    padding-top: 10px;
  }
  .agreement-content {
    height: 300px;
    overflow: auto;
  }
  .agreement-content-title {
    font-size: 16px;
    color: '#2d67ff';
    text-decoration: underline;
    font-weight: bold;
    padding: 10px 0px;
  }
  .agreement-content-body {
    font-size: 12px;
    
  }